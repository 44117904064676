:root {
    --lightblue: #012f49;
    --darkblue: #011b2c;
    --signalblue: #4579FF;
    --white: #F9F9F9; 
    --darkgrey: #202635; 
    --oceanblue: #0F3D5B; 
    --bluegrey: #262F46; 
    --mediumbluegrey: #495361; 
    --mediumgrey: #5A5C61; 
    --lightgrey: #949494; 
    --red: #FF6E6E; 
    --green: #6EF;
    
}

html {
    background-image: linear-gradient(
        to right,
        var(--darkblue),
        var(--lightblue)
    );
    overflow-x: hidden;
}

body {
    overflow-x: hidden;
    margin: 0;
    width: 100%;
    display: block;
    background-image: linear-gradient(
        to right,
        var(--darkblue),
        var(--lightblue)
    );
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body > * {
    box-shadow: 0px black;
}
#root {
    display: block;
    height: 100%;
}
.contact-container {
    margin: 1em;
    color: aliceblue; 
}
.contact-container > h1 {
    color: aliceblue;
    border-bottom: 1px solid aliceblue;
}
.main-container {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        to right,
        var(--darkblue),
        var(--lightblue)
    );
}
.success-container{
      margin: 1em;
    color: aliceblue; 
}
.success-container > h1 {
    color: aliceblue;
    border-bottom: 1px solid aliceblue;
}
header {
    padding-bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0.5em;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
}
footer {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #011b2c;
    vertical-align: middle;
    color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
main {
}
.preview-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-radius: 10px;
    margin: 1em;
    color: white;
    background-color: #011b2c;
}
.preview-header > a {
    text-decoration: none;
    color: white;
    text-decoration: underline;
}
.preview-header > * {
    width: auto;
    margin: 1em;
    height: auto;
}
.movies-preview {
    height: auto;
    /* background-color: aliceblue;*/
    margin: 1.5em;
    border-radius: 10px;
}

.movies-preview > img {
    height: 200px;
    width: auto;
    margin: 1em;
}
.schedule-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 1em;
}
.schedule-preview > a {
    text-decoration: none;
    color: #011b2c;
}
.preview-card > img {
    height: 200px;
    padding: 0.5em;
    margin: auto;
    
    margin-bottom: 0.5em; 
    
}
.preview-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    max-width: auto;
    color: aliceblue; 
    border-radius: 20px;
    margin: 0.5em;

}
.preview-card:hover {
   
 box-shadow:
  0px 0px 438.5px rgba(0, 0, 0, 0.137),
  0px 0px 367.8px rgba(0, 0, 0, 0.203),
  0px 0px 500px rgba(0, 0, 0, 0.34)
;

}

img {
    max-height: 50vh;
    border-radius: 20px;
}
#movies-preview
    > div
    > div
    > div
    > div.slider-single.active
    > div.slider-single-content {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
#movies-preview
    > div
    > div
    > div
    > div.slider-single.proactive
    > div.slider-single-content {
    box-shadow: none;
}
#movies-preview
    > div
    > div
    > div
    > div.slider-single.preactive
    > div.slider-single-content {
    box-shadow: none;
}
#movies-preview
    > div
    > div
    > div
    > div.slider-single.active
    > div.slider-right
    > div {
    border: none;
}
#movies-preview
    > div
    > div
    > div
    > div.slider-single.active
    > div.slider-left
    > div {
    border: none;
}
#movies-preview
    > div
    > div
    > div
    > div.slider-single.active
    > div.slider-right
    > div {
    border: 1px white;
    border-radius: 2px;
}
.movie-details-desc {
    border-radius: 20px;
}
.movies-overview-container {
    margin: 1em;
}
.movies-overview-container > h1 {
    color: aliceblue;
    border-bottom: 1px solid aliceblue;
}

.movies-overview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
}
.movies-overview > a {
    text-decoration: none;
    color: #011b2c;
}
.programm-overview-container {
    margin: 1em;
}
.programm-overview-container > h1{
   
     color: aliceblue;
    border-bottom: 1px solid aliceblue;
}

.date-bar-container {
    display: flex; 
    flex-direction: row; 
    
    overflow-x: scroll;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--darkblue); 
    color: aliceblue; 
    margin: 1em; 
    border-radius: 20px; 
    padding-left: 1em; 
     padding-right: 1em; 
    padding-top: 0.2em; 
    padding-bottom: 0.2em; 
}
.date-cell {
    text-align: center; 
    margin-right: 2em; 
    padding: 0.2em; 
    border-radius: 10px; 
}
.date-cell-active {
    background-color: var(--lightblue); 
    box-shadow:
  0px 0px 438.5px rgba(0, 0, 0, 0.137),
  0px 0px 367.8px rgba(0, 0, 0, 0.203),
  0px 0px 500px rgba(0, 0, 0, 0.34)
;
}

.programm-card {
    display: flex; 
    background-color: var(--oceanblue); 
    margin: 1em; 
    border-radius: 20px; 
   
    height: fit-content; 
}
.programm-card > img {
    border-top-right-radius: 0px; 
    border-bottom-right-radius:0px; 
    margin-right: 2em; 
    height: auto;
    max-height: 35vh;
    
}
.programm-card-details {
   margin-top: 1em; 
    margin-bottom: 1em; 
    color: aliceblue; 
     display: flex; 
    flex-direction: column; 
    overflow-x: scroll; 
}
.programm-card-details > h2{
     color: aliceblue;
   
    border-bottom: 1px solid aliceblue;
}
.screening-times-container {
    display: flex; 
    flex-direction: row; 
    flex-wrap: wrap;
}
.screening-times-cell {
    padding: 0.5em; 
    margin: 0.5em; 
    background-color: var(--darkblue); 
    color: aliceblue; 
    border-radius: 10px; 
    text-align: center; 
}
a {
    text-decoration: none; 
}
.screening-times-cell-active {
     background-color: var(--lightblue); 
    box-shadow:
  0px 0px 438.5px rgba(0, 0, 0, 0.137),
  0px 0px 367.8px rgba(0, 0, 0, 0.203),
  0px 0px 500px rgba(0, 0, 0, 0.34)
;
}
.reservation-form-container{
    display: flex; 
    flex-direction: column; 
    align-content: center; 
    justify-content: center; 
}
.reservation-form-container > h1 {
    color: aliceblue; 
    text-align: left; 
    margin-left:  0.5em; 
    border-bottom: 1px solid aliceblue ; 
}
.reservation-form {
    display: flex; 
    flex-direction: row; 
    margin: 1em; 
    color: aliceblue; 
}

.reservation-details {
   /* width: 35%; */
    width: 50vw; 
}

.screen {
  text-align: center; 
  height: 100px;  
 border-top: 10px solid white; 
}
.seating-plan {
    width: 100%;
    overflow-x: scroll; 
    
}
.seating-plan-container {
    
    display: flex; 
    flex-direction: column; 
    align-content: center; 
    justify-content: center; 
}
.seating-row{
    display: flex; 
    width: auto; 
    flex-direction: row;
    /*align-content: center; */
    justify-content: center;
}
.seat {
    /*
    width: auto; 
    height: auto; */
    width: 1.5em;
    height: 1.5em; 
    background-color: aliceblue; 
    color: var(--darkblue); 
    text-align: center; 
    margin: 0.5em; 
    border-radius: 5px; 
    overflow-x: auto; 
    
}
.seat-reserved {
    background-color: red; 
}
.seat-blocked {
    background-color: black; 
}
.seat-selected {
    background-color: dodgerblue; 
}
.reservation-box-container {
    background-color: var(--oceanblue); 
    border-radius: 20px; 
    padding: 0.5em; 
}
.reservation-box-info {
    display: flex; 
    flex-direction: row; 
    align-content: center; 
    justify-content: center; 
}
.reservation-box-info > div{
   margin: 0.5em; 
}
.reservation-box-seats-header {
     display: flex; 
    flex-direction: row; 
    align-content: center; 
    justify-content: center; 
}
.reservation-box-seats-header > div{
   margin: 0.5em; 
}
.reservation-item {
    width: auto; 
    text-align: center; 
    margin: 0.5em; 
    padding-right: 0.5em; 
    padding-left: 0.5em; 
    padding-top: 0.2em; 
    padding-bottom: 0.2em; 
    background-color: var(--darkblue); 
    border-radius: 10px; 
}

.personal-details-form-container {
    width: 100%; 
}
.personal-details-form {
    padding: 1em; 
    width: 100%; 
    display: flex; 
    flex-direction: column; 
}
.personal-details-form > input[type='text'] {
    margin: 1em; 
    border-radius: 10px; 
}
.btn-step {
    text-align: center; 
    width: 75%; 
    margin: 1em; 
    padding: 1em; 
    background-color: var(--darkblue); 
    color: aliceblue; 
    border-radius: 10px; 
}
.reservation-user {
    width: 100%; 
    overflow-x: scroll; 
    display: flex;
flex-direction: column;
align-content: center;
justify-content: center;
    align-items: center; 
    margin-top: 1em; 
}
.seating-plan-container {
        width: 100%; 
        margin: auto; 
        overflow-x: scroll; 
        flex-wrap: wrap;
        /*align-content:  flex-start;*/
         align-content: normal; 
    }
.screening-date-cell {
    display: flex; 
    flex-direction: column; 
   
    margin: 0.5em; 
    padding: 0.5em; 
}
.programm-card-dates {
    display: flex; 
    flex-direction: row; 
    
}
.reservation-movie-details {
    display: flex; 
    margin-bottom: 1em; 
    
}
.reservation-movie-details-img > img {
    height: 30vh; 
    
}
.reservation-movie-details-text {
    display: flex;
    margin: auto; 
    margin-left: 0.5em;
    flex-direction: column;
    align-content: center; 
    margin-left: 1.5em; 
    
}
.reservation-movie-details-text > h2 {
    text-align: center; 
}
.reservation-movie-details-text-infos {
    display: flex; 
    flex-direction: column; 
    
}
.reservation-box-seats {
    display: flex; 
    width: auto; 
    flex-direction: row; 
    align-content: flex-start; 
    align-items: flex-start; 
    justify-content: flex-start; 
    flex-wrap: wrap;
    
}
.error-container {
    color: white; 
    display: flex; 
    flex-direction: column; 
    align-content: center; 
    justify-content: center; 
    margin: 1em; 
}

@media only screen and (min-width: 600px) {
    .movie-details-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin: 1em;
    }

    .movie-details-desc {
        padding: 1em;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: middle;
    }
    .movie-details-img > img {
        border: 5px white;
    }
    .btn-booking-container {
        margin-top: 2em;
        position: inherit;
    }
    .movie-details-box-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
    }
    .movie-details {
        display: flex;
        flex-direction: column;
        margin: 1em;
        color: aliceblue;
        justify-content: center;
        align-content: center;
    }
    .movie-details-box {
        margin: auto;
        color: aliceblue;
        width: 5em;
        height: 5em;
        padding: 1em;
        border: 1px solid white;
        border-radius: 10px;
        text-align: center;
    }
    .movie-details-title > h2 {
        margin-left: 1em;
    }
    .btn-booking-container {
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: flex-end;
    }
    .btn-booking {
        position: absolute;
        right: 1em;
        width: 50%;
        border-radius: 30px;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        background-color: #011b2c;
        color: aliceblue;
        text-align: center;
        padding: 1em;
    }
     .seating-plan {
        width: 100%; 
    }
     
}
@media only screen and (max-width: 850px) {
    .movie-details {
        display: flex;
        flex-direction: column;
    }
    .movie-details-container {
        margin: 1em;

        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    .movie-details-img {
        height: 40vh;
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
    }
    .movie-details-box-container {
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 30%;
        margin: auto;
    }
    hr {
        border-top: 2px solid white;
    }
    .movie-details-title {
        color: aliceblue;
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
    }
    .movie-details-box {
        margin: auto;
        color: aliceblue;
        width: 5em;
        height: 5em;
        padding: 1em;
        border: 1px solid white;
        border-radius: 10px;
        text-align: center;
    }
    .movie-details-img > img {
        width: auto;
        height: auto;
    }
    .movie-details-desc {
        height: 25vh;

        padding-left: 1rem;
        padding-right: 1rem;
        border-top-left-radius: 2em;
        border-top-right-radius: 2em;

        color: aliceblue;
        overflow-y: scroll;
    }
    .btn-booking-container {
        bottom: 1rem;
        margin: auto;
        justify-content: center;
        align-content: center;
        width: 95%;
    }
    .btn-booking {
        width: 100%;
        border-radius: 30px;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        background-color: #011b2c;
        color: aliceblue;
        text-align: center;
        padding: 1em;
    }
    .programm-card{
        display: flex;
        flex-direction: column; 
        align-content: center; 
        justify-content: center; 
         box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }
    .programm-card-details {
        margin: 1em; 
        
    }
    .programm-card > img {
        border-radius: 20px; 
        width: auto; 
        height: auto; 
        margin: auto; 
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }
    .reservation-details {
        width: 100%; 
        margin-bottom: 0.5em; 
    }
    .reservation-form {
    width: auto; 
    display: flex; 
    flex-direction: column; 
    margin: 1em; 
    color: aliceblue; 
}
    .seating-plan {
        width: 100%; 
    }
    .seating-plan-container {
        width: 100%; 
        margin: auto; 
        overflow-x: scroll; 
        flex-wrap: wrap;
        /*align-content: flex-start;*/
    }
    .seat {
        overflow-x: unset; 
    }
    .btn-step {
        width: auto; 
    }
}
